<template>
    <UiHeader id="aprHeader" :blok="blok" @logout="logout" />
</template>

<script lang="ts" setup>
import { useAuthStore } from '../../_base/store/auth';
const authStore = useAuthStore();
const route = useRoute();

defineProps<{ blok: any }>();

async function logout() {
    await $publicApi('logout', {
        method: 'POST',
        body: JSON.stringify({ _token: authStore.csrf }),
        onResponse({ response }) {
            if (response.ok) {
                navigateTo(route.path, { external: true });
            }
        },
        onResponseError({ response }) {
            if (response) {
                console.error(response);
            }
        },
    });
}
</script>

<style lang="postcss">
#aprHeader {
    @apply bg-white text-text-primary-asphalt md:px-4;
    box-shadow: 0px 8px 16px 0px rgba(40, 44, 74, 0.04);
    [tabindex='0']:focus-visible,
    .is-single-button a:focus-visible {
        outline: 1px solid #18b2e1;
    }
    .container {
        @apply text-sm h-[80px];
    }

    .logo {
        @apply px-0 h-auto;
        img {
            width: 240px;
            height: 64px;
        }
    }

    .is-single-button.modal-button span {
        @apply relative inline-flex mr-4 justify-center items-center bg-none h-12 rounded-lg text-brand-primary-500 text-base uppercase font-semibold border-2 border-solid border-brand-primary-500 transition-colors;
        padding: 11.5px 24px 12.5px 24px !important;
        &:hover {
            @apply text-brand-primary-light-30 border-brand-primary-light-30;
            text-decoration: none !important;
        }
    }

    .nav {
        @apply md:w-[calc(100%_-_245px)];
        > li {
            @apply py-[12px];
        }

        > .nav-row > .nav-row-item {
            @apply px-4;
            &.button {
                @apply px-0;
                span,
                a {
                    @apply px-4;
                }
            }
        }

        .nav-row {
            @apply flex-wrap w-full;

            .nav-row-item {
                @apply transition-[background-color] duration-[0.3s] ease-[ease];
                .i-ri\:arrow-down-s-line {
                    @apply text-base leading-3 w-5 h-5 flex justify-center items-center ml-2;
                }
                .i-ri\:arrow-right-s-line {
                    @apply text-xs;
                    &:before {
                        @apply w-[7.5px] h-3 flex items-center;
                    }
                }
                a {
                    @apply text-text-primary-asphalt px-4;
                }

                @apply gap-x-[7px] cursor-pointer;

                > a,
                > span {
                    @apply text-text-primary-asphalt;
                }

                > a {
                    @apply relative pr-14;
                    .iconify {
                        @apply absolute right-4;
                    }
                }

                > span {
                    @apply font-semibold sm:text-base;
                }

                &.active {
                    /* @apply bg-[#3b3f43]; */
                    & > span {
                        @apply no-underline;
                    }
                }

                > .nav-dropdown:first-of-type {
                    > li:first-of-type > a {
                        @apply pt-4;
                    }
                }

                &:hover {
                    & > span {
                        /* @apply underline; */
                    }
                }
                &.is-single-button {
                    @apply p-0;
                    a,
                    span {
                        @apply p-4 text-base font-semibold text-text-primary-asphalt;
                    }
                    a {
                        @apply hover:underline;
                    }
                    &.modal-button {
                        @apply ml-auto;
                    }
                }

                &.my-account {
                    @apply ml-auto;
                }

                &.rich-text-menu-item {
                    a {
                        @apply p-[0.7rem];

                        .simple-richtext {
                            @apply leading-normal;
                            span {
                                &.text {
                                    @apply text-[0.8rem];
                                }
                            }
                        }
                    }
                }
            }

            span {
                @apply px-0;
            }
        }

        .nav-dropdown {
            @apply text-text-primary-asphalt bg-white top-[80px] min-w-[auto] pb-6 rounded-lg;
            filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.16));

            &.right {
                @apply left-auto right-0;
            }

            &.left {
                @apply left-0 right-auto;
            }
            .nav-dropdown-item,
            .nav-row-item {
                a:hover {
                    @apply bg-white;
                }

                &.active > a {
                    @apply underline;
                    .iconify {
                        @apply no-underline;
                    }
                }

                a {
                    @apply flex w-full relative items-center justify-between leading-8 transition-[background-color] duration-[0.3s] ease-[ease];

                    .iconify {
                        @apply pl-10;
                    }
                }
            }

            .nav-dropdown-item a:hover {
                @apply underline;
            }

            &.inner {
                /* @apply top-[0] left-0 items-baseline cursor-auto bg-white min-w-full min-h-full block pt-0 grid-flow-col pb-4 rounded-lg -z-10;
                filter: none;
                padding-left: 100%; */
                @apply top-[0] left-full items-baseline cursor-auto bg-white min-w-full min-h-full block pt-0 grid-flow-col pb-4 rounded-lg -z-10 pl-3 -ml-3;
                filter: none;
                /* padding-left: 100%; */
                a {
                    @apply transition-[background-color] duration-[0.3s] ease-[ease];
                }
                a:hover {
                    @apply underline;
                }
                .top-link a {
                    @apply pt-4;
                }
            }
        }
    }

    .nav > .nav-row > .nav-row-item > a {
        @apply px-0;
    }

    .mobile-menu.new-header .scroll-wrapper .scroll > ul > hr {
        @apply border-none border-b;
    }

    .mobile-menu.new-header .scroll-wrapper .scroll > ul > hr,
    .mobile-menu.new-header .scroll-wrapper .scroll .level-wrapper hr {
        max-width: calc(100% - 16px);
    }

    .mobile-menu li div > img {
        @apply h-5 w-5;
    }

    /* .mobile-menu.new-header li:first-of-type a {
        @apply pt-8;
    } */

    @media (max-width: 1280px) {
        .mobile-fall-menu {
            @apply hidden;
            background: inherit;

            &.active {
                @apply block;
            }
        }
        .navigation-button {
            @apply w-14 h-[80px] text-text-primary-asphalt text-base flex justify-center items-center;
        }
        .nav {
            @apply w-auto;
        }
        .mobile-menu {
            @apply block w-[300px];
            .scroll-wrapper .scroll {
                grid-template-columns: repeat(3, 300px);
                &.level-2 {
                    transform: translateX(-300px);
                }
                &.level-3 {
                    transform: translateX(-600px);
                }
            }
            li {
                @apply text-[#293f4d];
                [data-icon='angle-right'] {
                    @apply text-base;
                }
            }
            li.drop-down-button .iconify {
                @apply text-base;
            }
        }
        .nav-row {
            @apply hidden;
        }
        .container {
            @apply justify-between;
        }
    }

    @media (max-width: 768px) {
        @apply relative;
        .mobile-menu.new-header .scroll-wrapper .scroll > ul,
        .mobile-menu.new-header .scroll-wrapper .scroll .level-wrapper {
            @apply pr-8 pt-0;
        }
        .level-1 > ul {
            @apply pr-8;
        }
        .container {
            @apply h-[56px];
        }

        .logo {
            img {
                width: 150px;
                height: 40px;
            }
        }

        .navigation-button {
            @apply h-[56px] w-14 top-0 right-0;
        }
    }

    .back-button,
    .title,
    .mobile-menu li {
        @apply text-[#293f4d];
    }
    .nav > .nav-row > .nav-row-item {
        &:first-of-type {
            @apply xl:ml-[20px] 2xl:ml-auto;

            @media (max-width: 1295px) {
                @apply ml-auto;
            }
        }
    }
}
</style>
